import "../../../App.css";
import "./LinksSection.css";

import Content from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";

import Slider from "react-slick";

import { useRef } from "react";
import Config from "../../../config";

interface Props {
  className?: string;
}

export function PressCarousel(p: Props) {
  const t = Content.shared.t;
  const mentionedIn = [
    {
      url: "https://www.travolution.com/news/travel-sectors/air/westjet-to-pilot-digital-travel-documentation-verification-system/",
      img: Images.logos.travolution,
    },
    {
      url: "https://2zfq4r3trpub14lqo62u0d9p-wpengine.netdna-ssl.com/wp-content/uploads/2021/02/Zamna-The-TimesRaconteur.pdf",
      img: Images.logos.times,
    },
    {
      url: "https://youtu.be/GXcTnN_wlg8",
      img: Images.logos.sky,
    },
    {
      url: "https://www.wired.co.uk/article/passport-check-blockchain",
      img: Images.logos.wired,
    },
    {
      url: "https://www.bbc.co.uk/news/business-45628825",
      img: Images.logos.bbc,
    },
    {
      url: "https://www.forbes.com/sites/oliversmith/2018/05/21/blockchain-startup-vchain-is-flying-high-after-its-royal-seal-of-approval/?sh=3f38f5721ded",
      img: Images.logos.forbes,
    },
    {
      url: "https://www.cityam.com/zamna-raises-5m-as-it-secures-deal-with-iag-for-airport-security-tech/",
      img: Images.logos.cityam,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: Config.carouselDelay,
    centerMode: true,
    variableWidth: true,
  };

  return (
    <div className="mentioned-in">
      <Slider {...settings}>
        {mentionedIn.map(({ url, img }) => (
          <div className="carousel-item" key={img}>
            <a target="_blank" rel="noopener noreferrer" href={url}>
              <div>
                <img src={img} className="partner-logo" />
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}
