import "./BigNumberBanners.css";

import anime from "animejs/lib/anime.es.js";
import { use } from "i18next";
import { DOMElement, ReactNode, useEffect, useRef } from "react";

import Images from "../../../../assets/images";
import Content from "../../../../content/content";
import NumberBanner from "./NumberBanner";

export default function BigNumberBanners() {
  const t = Content.shared.t;
  const isCountingUpRef = useRef(false);

  function countUp(el: Element, target: number) {
    console.log("counting up:", target);
    let data = { count: 0 };

    anime({
      targets: data,
      count: [0, target],
      duration: 3000,
      round: 1,
      delay: 200,
      easing: "easeOutCubic",
      update() {
        (el as HTMLParagraphElement).innerText =
          `${data.count.toLocaleString()}+`;
      },
    });
  }

  function makeCountUp(el: Element) {
    if (!el) {
      return;
    }
    const text = el.textContent;

    if (!text) {
      return;
    }
    const target = parseInt(text, 10);

    const io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          countUp(el, target);
          io.unobserve(entry.target);
        }
      });
    });

    io.observe(el);
  }

  useEffect(() => {
    if (isCountingUpRef.current) {
      return;
    }
    isCountingUpRef.current = true;

    const els = document.querySelectorAll(".big-number");
    els.forEach(makeCountUp);
  }, []);

  return (
    <div className="root-big-numbers-banners">
      <NumberBanner
        value={1000}
        icon={<img alt="passport icon" src={Images.icons.bannerPassport} />}
        title={t("banners.visas")}
        mobileTitle={t("banners.visas-mobile")}
      />

      <NumberBanner
        value={70_000_000}
        icon={<img alt="shield icon" src={Images.icons.bannerShield} />}
        title={t("banners.travel-docs")}
        mobileTitle={t("banners.travel-docs-mobile")}
      />
      <NumberBanner
        value={20}
        icon={<img alt="globe icon" src={Images.icons.bannerGlobe} />}
        title={t("banners.years-experience")}
        mobileTitle={t("banners.years-experience-mobile")}
      />
      <NumberBanner
        value={180}
        icon={<img alt="plane icon" src={Images.icons.bannerPlane} />}
        title={t("banners.countries-supported")}
        mobileTitle={t("banners.countries-supported-mobile")}
      />
    </div>
  );
}
