import "../../../App.css";
import "./Footer.css";

import clsx from "clsx";

import Content, { ContentNS } from "../../../content/content";
import { extenalLinks } from "../../../content/external-links";

export default function Footer() {
  const t = Content.shared.t;
  const year = new Date().getFullYear();

  return (
    <div className={clsx("footer")}>
      <div className="page-section footer-section">
        <div className="footer-content">
          <div className="company-name">{`${t("footer.copyright", { ns: ContentNS.main })}`}</div>
          <div className="footer-links">
            <a
              target="_blank"
              href={extenalLinks.privacyNotice}
              rel="noopener noreferrer"
            >{`${t("footer.privacy-notice")}`}</a>
            <a
              target="_blank"
              href={extenalLinks.termsAndConditions}
              rel="noopener noreferrer"
            >{`${t("footer.terms-conditions")}`}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
