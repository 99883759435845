import "../../../App.css";
import "./ScanningIsNotEnoughVideo.css";

import Content, { ContentNS } from "../../../content/content";
import Images from "../../../assets/images";
import { useEffect, useRef } from "react";

interface Props {
  className?: string;
}

export function ScanningIsNotEnoughVideo(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!(videoRef && videoRef.current)) {
      return;
    }
  }, [videoRef]);

  return (
    <div className="what-we-do" ref={containerRef} dir={textDir}>
      <div className="what-we-do-title">{`${t("scanning-is-not-enough", {
        ns: ContentNS.business,
      })}`}</div>

      <div className="process-animation">
        <div className="process-animation-frame">
          <video
            className="video-animation"
            ref={videoRef}
            controls={true}
            preload="auto"
            autoPlay={false}
            muted={false}
            poster={Images.scanningIsNotEnoughPlayOverlay}
            webkit-playsinline="true"
            playsInline
          >
            <source src={Images.scanningIsNotEnough} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="start-saving-title">
        <a href="https://www.zamna.com/" className="forward-button">
          {`${t("forward-button", {
            ns: ContentNS.business,
          })}`}
        </a>
      </div>
    </div>
  );
}
