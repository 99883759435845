import "./WeAreGlobal.css";

import Images from "../../../assets/images";
import Content, { ContentNS } from "../../../content/content";

export default function WeAreGlobal() {
  const text = (s: string) => Content.shared.t(s, { ns: ContentNS.main });

  return (
    <div className="we-are-global">
      <div className="we-are-global-content">
        <h2 className="we-are-global-title">{text("we-are-global")}</h2>
        <img
          className="we-are-global-image-desktop"
          src={Images.weAreGlobalDesktop}
          alt="world map"
        />

        <a href="#footer" className="get-in-touch-button">
          {text("get-in-touch")}
        </a>

        <img
          className="we-are-global-image-mobile"
          src={Images.weAreGlobalMobile}
          alt="world map"
        />
      </div>
    </div>
  );
}
