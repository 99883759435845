import "./NumberBanner.css";

interface NumberBannerProps {
  value: number;
  icon: React.ReactNode;
  title: string;
  mobileTitle?: string;
}

export default function NumberBanner(p: NumberBannerProps) {
  return (
    <div className="root-number-banner">
      <div className="first-row">
        <p className="big-number">{`${p.value}+`}</p>
        {p.icon}
      </div>
      <p className="banner-title">{p.title}</p>
      <p className="banner-title-mobile">{p.mobileTitle || ""}</p>
    </div>
  );
}
