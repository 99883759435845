import "./App.css";

import React, { useEffect, useRef } from "react";

import { BusinessPage } from "./components/business-page/BusinessPage";
import { ScanningIsNotEnough } from "./components/business-page/ScanningIsNotEnough";
import Header from "./components/common/header/Header";
import CookiesPolicy from "./components/common/legal/got-questions/CookiesPolicy";
import PrivacyNotice from "./components/common/legal/got-questions/PrivacyNotice";
import TermsAndConditions from "./components/common/legal/got-questions/TermsAndConditions";
import CookiesBanner from "./components/cookies-banner/CookiesBanner";
import { PassengerPage } from "./components/passenger-page/PassengerPage";
import Config from "./config";
import Content, { ContentNS } from "./content/content";
import { Page, useAppStore } from "./stores/app-store";

export default function App() {
  const appRef = useRef(null);
  const assetsLoaded = useAppStore((state) => state.assetsLoaded);
  const language = useAppStore((state) => state.language);
  const markAssetLoaded = useAppStore((state) => state.markAssetLoaded);
  const isConsentGiven = useAppStore((state) => state.isConsentGiven);
  const currentPage = useAppStore((state) => state.currentPage);

  useEffect(setupContent, [appRef]);
  useEffect(setupGoogleAnalytics, [isConsentGiven]);

  return (
    <div className="app" ref={appRef}>
      <Header />
      {assetsLoaded ? <AppContent /> : "..."}
      {shouldShowCookieBanner() && (
        <div className="cookie-container">
          <CookiesBanner />
        </div>
      )}
    </div>
  );

  function shouldShowCookieBanner(): boolean {
    return (
      isConsentGiven === null &&
      currentPage !== Page.privacyNotice &&
      currentPage !== Page.termsAndConditions
    );
  }

  function setupContent() {
    let supportedLanguages = ["en"];
    let namespaces = [ContentNS.main];

    if (currentPage === Page.business) {
      supportedLanguages = Config.supportedLanguages.b2b;
      namespaces.push(ContentNS.business);
    }

    if (currentPage === Page.passenger) {
      supportedLanguages = Config.supportedLanguages.b2c;
    }

    if (currentPage === Page.scanningIsNotEnough) {
      supportedLanguages = ["en"]; // only English
      namespaces.push(ContentNS.business);
    }

    Content.shared.setup(
      language,
      supportedLanguages,
      namespaces,
      markAssetLoaded
    );
  }

  function setupGoogleAnalytics() {
    if (!isConsentGiven) {
      return;
    }

    const button = document.getElementById("ga-button");
    button?.click();
  }

  function AppContent() {
    switch (currentPage) {
      case Page.business:
        return <BusinessPage />;
      case Page.passenger:
        return <PassengerPage />;
      case Page.privacyNotice:
        return <PrivacyNotice />;
      case Page.termsAndConditions:
        return <TermsAndConditions />;
      case Page.cookiesPolicy:
        return <CookiesPolicy />;
      case Page.scanningIsNotEnough:
        return <ScanningIsNotEnough />;
    }
    return (
      <React.Fragment>
        <PassengerPage />
      </React.Fragment>
    );
  }
}
