import { LinksSection } from "../common/links-section/LinksSection";
import { Faq } from "./faq/Faq";
import Footer from "./footer/Footer";
import { HowBanners } from "./how-banners/HowBanners";
import { PrivacyPrinciple } from "./privacy-principle/PrivacyPrinciple";
import { ProtectingData } from "./protecting-data/ProtectingData";
import { WhoWeAre } from "./who-we-are/WhoWeAre";
import { ZamnaIntro } from "./zamna-intro/ZamnaIntro";

export function PassengerPage() {
  return (
    <div className="passenger-page">
      <ZamnaIntro />
      <WhoWeAre />
      <ProtectingData />
      <PrivacyPrinciple className="zamna-principle" />
      <HowBanners />
      <LinksSection />
      <Faq />
      <Footer />
    </div>
  );
}
