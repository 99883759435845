import "../../../App.css";
import "./BusinessPageIntro.css";

import { Fragment } from "react";

import Images from "../../../assets/images";
import Content, { ContentNS } from "../../../content/content";

interface Props {
  className?: string;
}

export function BusinessPageIntro(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();

  return (
    <Fragment>
      <div
        className={"page-intro"}
        style={{
          backgroundImage: `url(${Images.b2bMain})`,
        }}
      >
        <div className="page-intro-content">
          <p className="motto" dir={textDir}>
            {`${t("motto", { ns: ContentNS.business })}`}
          </p>
          <div className="motto-description" dir={textDir}>
            <div>
              {`${t("motto-description-1", { ns: ContentNS.business })}`}
            </div>
            <div>
              {`${t("motto-description-2", {
                ns: ContentNS.business,
              })}`}
            </div>
          </div>
        </div>
      </div>
      <div
        className="page-intro-image"
        style={{
          backgroundImage: `url(${Images.b2bMain})`,
        }}
      />
    </Fragment>
  );
}
