import "../../../App.css";
import "./WhatWeDo.css";

import Content, { ContentNS } from "../../../content/content";
import Images from "../../../assets/images";
import { useEffect, useRef } from "react";
import { useAppStore } from "../../../stores/app-store";

interface Props {
  className?: string;
}

export function WhatWeDo(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();
  const language = useAppStore((state) => state.language);
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const autoPlay = window.location.hash === "#what-we-do";

  useEffect(() => {
    if (!(videoRef && videoRef.current)) {
      return;
    }
    if (autoPlay) {
      setTimeout(() => {
        if (containerRef && containerRef.current) {
          containerRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
        if (videoRef && videoRef.current) {
          var promise = videoRef.current.play();
          if (promise !== undefined) {
            promise
              .then((_) => {
                // Autoplay started!
              })
              .catch((error) => {
                // Autoplay was prevented.
              });
          }
        }
      }, 100);
    }
  }, [videoRef]);

  return (
    <div className="what-we-do" ref={containerRef} dir={textDir}>
      <div className="what-we-do-title">{`${t("what-we-do", {
        ns: ContentNS.business,
      })}`}</div>

      <div className="process-animation">
        <div className="process-animation-frame">
          <video
            className="video-animation"
            ref={videoRef}
            controls={true}
            preload="auto"
            autoPlay={autoPlay}
            muted={autoPlay}
            poster={Images.b2bPlayOverlay}
            webkit-playsinline="true"
            playsInline
          >
            <source src={Images.whatWeDoVideo(language)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="start-saving-title">{`${t("start-saving", {
        ns: ContentNS.business,
      })}`}</div>
    </div>
  );
}
