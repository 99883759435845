import "./ZamnaIntro.css";
import "../../../App.css";

import Images from "../../../assets/images";
import Content from "../../../content/content";

interface Props {
  className?: string;
}

export function ZamnaIntro(p: Props) {
  const t = Content.shared.t;

  return (
    <div className="zamna-intro">
      <div className="page-section">
        <div className="zamna-intro-desktop">
          <div className="intro-content">
            <h1 className="easy-verify-title">{`${t("easy-verify")}`}</h1>
            <h4 className="no-apps-title">{`${t("no-apps")}`}</h4>
          </div>
          <img alt="globe" className="globe-pic" src={Images.globe} />
        </div>

        <div className="zamna-intro-mobile">
          <div className="zamna-globe"></div>
          <h3 className="easy-verify-title">{`${t("easy-verify")}`}</h3>
          <h5 className="no-apps-title">{`${t("no-apps")}`}</h5>
        </div>
      </div>
    </div>
  );
}
