import "../../../../App.css";
import "./Legal.css";
import Images from "../../../../assets/images";

export default function CookiesPolicy() {
  return (
    <div className="cookies-policy">
      <div className="cookies-policy-content">
        <img src={Images.logos.zamna} />
        <br />
        <br />
        <h2>COOKIE POLICY</h2>
        <br />
        <p>
          This Cookie Policy explains how Zamna ("we", "us", or "our") uses
          cookies and similar tracking technologies when you visit our website
          or our white label websites deployed with trusted clients. By using or
          accessing the Zamna website, you agree to the use of cookies as
          described in this policy. For use of the white label websites, consent
          for use of cookies is gathered.
          <br />
          <br />
          <b>What are Cookies?</b>
        </p>
        <p>
          Cookies are small text files that are placed on your computer or
          device by websites that you visit. They are widely used to make
          websites work more efficiently and provide information to improve
          services.
          <br />
          <br />
          <b>Types of Cookies We Use</b>
        </p>
        <p>
          • Essential Cookies:These cookies are necessary for the proper
          functioning of our website. They enable you to navigate our site and
          use its features.
          <br />
          • Analytical/Performance Cookies (Google Analytics): We use Google
          Analytics, a web analytics service provided by Google, Inc. Google
          Analytics uses cookies to help us analyze how users use our website
          and white label websites. The information generated by the cookie
          about your use of the website (including your IP address) will be
          transmitted to and stored by Google on servers in the United States.
          Google will use this information for the purpose of evaluating your
          use of the website, compiling reports on website activity for website
          operators, and providing other services relating to website activity
          and internet usage. Google may also transfer this information to third
          parties where required to do so by law, or where such third parties
          process the information on Google's behalf.
          <br />
          <br />
          <b>How to Control Cookies</b>
        </p>
        <p>
          You can control and/or delete cookies as you wish. You can delete all
          cookies that are already on your computer and you can set most
          browsers to prevent them from being placed. If you do this, however,
          you may have to manually adjust some preferences every time you visit
          a site, and some services and functionalities may not work.
          <br />
          <br />
          <b>Changes to This Cookie Policy</b>
        </p>
        <p>
          We may update our Cookie Policy from time to time. We encourage you to
          periodically review this page for the latest information on our cookie
          practices.
          <br />
          <br />
          <b>Contact Us</b>
        </p>
        <p>
          If you have any questions about our use of cookies, please contact us
          at info@zamna.com
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>{" "}
      </div>
    </div>
  );
}
