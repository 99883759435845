import "../../App.css";
import "./CookiesBanner.css";
import Content from "../../content/content";
import clsx from "clsx";
import Images from "../../assets/images";
import { useAppStore } from "../../stores/app-store";
import { extenalLinks } from "../../content/external-links";

export default function CookiesBanner() {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();
  const giveConsent = useAppStore((state) => state.giveConsent);

  return (
    <div className={clsx("cookies-banner")}>
      <img src={Images.cookie} alt="icon of a tasty cookie"/>

      <div className="cookies-content" dir={textDir}>
        <span>{`${t("cookie-info-1")}`}</span>
        <a
          target="_blank"
          href={extenalLinks.termsAndConditions}
          rel="noopener noreferrer"
        >{`${t("cookie-info-2")}`}</a>
        <span>{`${t("cookie-info-3")}`}</span>
        <a
          target="_blank"
          href={extenalLinks.privacyNotice}
          rel="noopener noreferrer"
        >{`${t("cookie-info-4")}`}</a>
        <span>{`${t("cookie-info-5")}`}</span>
      </div>

      <div className="cookies-buttons">
        <button
          className="cookie-button accept"
          onClick={() => giveConsent(true)}
          onTouchStart={() => giveConsent(true)}
        >{`${t("ui.button.accept")}`}</button>
        <button
          className="cookie-button deny"
          onClick={() => giveConsent(false)}
          onTouchStart={() => giveConsent(false)}
        >{`${t("ui.button.deny")}`}</button>
      </div>
    </div>
  );
}
