import "../../../App.css";
import "./PrivacyPrinciple.css";

import Content from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";

interface Props {
  className?: string;
}

export function PrivacyPrinciple(p: Props) {
  const t = Content.shared.t;

  return (
    <div className={clsx("privacy-principle", p.className)}>
      <div className="page-section principle">
        <div className="principle-container">
          <h2 className="principle-title">{`${t("zamna-privacy-title")}`}</h2>
          <div className="principle-banner">
            <img className="icon-never-see" src={Images.iconNeverSee} />
            <div className="principle-banner-text">
              <span>{`${t("zamna-privacy-banner-1")}`}</span>
              <span>{`${t("zamna-privacy-banner-2")}`}</span>
              <span>{`${t("zamna-privacy-banner-3")}`}</span>
            </div>
          </div>
          <p className="principle-text">{`${t("zamna-privacy-text")}`}</p>
        </div>
      </div>
    </div>
  );
}
