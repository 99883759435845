const location = window.location;

export const extenalLinks = {
  privacyNotice: `${location.origin}/privacy-notice/${location.search}`,
  termsAndConditions: `${location.origin}/terms-and-conditions/${location.search}`,
  cookiesPolicy: `${location.origin}/cookies-policy/${location.search}`,
  b2bnews1:
    "https://www.breakingtravelnews.com/news/article/airlines-leaving-half-a-billion-pounds-on-the-table-new-data-reveals/",
  b2bnews2:
    "https://www.phocuswire.com/westjet-trial-zamna-tech-accelerate-passenger-check-in",
  b2bnews3:
    "https://www.sita.aero/pressroom/news-releases/sita-partners-with-zamna-to-digitise-travel-processes-for-airlines-airports-and-governments/",
  b2bnews4:
    "https://www.intelligentcio.com/north-america/2023/03/08/zamna-partners-with-westjet-to-accelerate-seamless-passenger-check-in/",
  linkedin: "https://www.linkedin.com/company/zamna/",
  medium: "https://zamna.medium.com/",
};
