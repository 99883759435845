import "../../../../App.css";
import "./Legal.css";
import { extenalLinks } from "../../../../content/external-links";
import Images from "../../../../assets/images";

export default function PrivacyNotice() {
  return (
    <div className="privacy-notice">
      <div className="privacy-notice-content">
        <img src={Images.logos.zamna} />
        <br />
        <br />
        <h2>PRIVACY NOTICE</h2>
        <br />
        <p>
          Welcome to our privacy notice. Zamna Technologies Limited (”we, the
          Company”) is committed to protecting and respecting your privacy. This
          privacy notice will inform you as to how we look after your personal
          data when you visit our website (regardless of where you visit it
          from) and tell you about your privacy rights and how the law protects
          you.
          <br />
          <br />
          This privacy notice aims to give you information on how we collect and
          process your personal data through your use of this website, including
          any data you may provide directly through this website.
        </p>
        <p>
          Please read the following carefully to understand our views and
          practices regarding your personal data and how we will use it. <br />
          <br />
          For the purpose of the Data Protection Act 2018 (the Act), we are the
          data controller. Our Data Protection Officer is Aleksandr Gorelik. Our
          registered address is 124 City Road, London, EC1V 2NX. Our ICO
          registration number is ZA569327. <br />
          <br />
          You may contact us to find out more about the way we use personal data
          via email at privacy@zamna.com.
        </p>
        <p>
          <br />
          You have the right to make a complaint at any time to the Information
          Commissioner’s Office (ICO), the UK supervisory authority for data
          protection issues (www.ico.org.uk). We would, however, appreciate the
          chance to deal with your concerns before you approach the ICO so
          please contact us in the first instance.
          <br />
          <br />
          <b>IF YOU DO NOT PROVIDE PERSONAL DATA</b>
        </p>
        <p>
          If we need to process the data you provide us with to fulfil our
          service, and you fail to provide that data when requested, we may not
          be able to perform the service, in which case, we may have to cancel a
          service you have with us. We will of course notify you if we need to
          cancel a service.
          <br />
          <br />
          <b>WHAT INFORMATION DO WE USE?</b>
        </p>
        <p>
          We will collect and process the following information about you:
          <br />
          • Information you give us about you. You give us this information by
          filling in forms on our website (zamna.com) that you may access
          directly or through another website, or by corresponding with us by
          e-mail. It only includes basic personal data required in order for you
          to use and report any problems with our website. <br />
          • Information we collect about you. With regard to each of your visits
          to our website we will automatically collect the following
          information:
          <br />
          • technical information, including device identifiers, device type,
          geo-location information, connection information, statistics on page
          views, traffic to and from the website, the Internet Protocol (IP)
          address used to connect your computer to the internet, your login
          information, browser type and version, time zone setting, browser
          plug-in types and versions, operating system, ad data and platform;
          and <br />
          • information based on your activities on the website, including the
          mobile network information, full Uniform Resource Locators (URL),
          clickstream to, through and from our website (including date and
          time), products you viewed or searched for, page response times,
          download errors, length of visits to certain pages, page interaction
          information (such as scrolling, clicks, and mouse-overs), methods used
          to browse away from the page, and any phone number used to call our
          customer service number.
          <br />
          • Information we receive from other sources. This is information we
          receive about you if you use any of the other websites we operate or
          the other services we provide. We are working closely with third
          parties (e.g. business partners, sub-contractors in technical and
          delivery services, search information providers). We will notify you
          when we receive personal data about you from other sources and the
          purposes for which we intend to use that information.
          <br />
          <br />
          <b>COOKIES</b>
          <br />
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and allows us to improve our website. This website
          uses a cookie control system which you accept on your first site
          visit. For detailed information on the cookies we use and the purposes
          for which we use them, see our&nbsp;
          <a href={extenalLinks.cookiesPolicy} rel="noopener noreferrer">
            Cookie Policy
          </a>
          .
          <br />
          <br />
          <b>HOW DO WE USE THIS INFORMATION?</b>
        </p>
        <p>
          We will only use personal data when the law allows us to. You will not
          be subject to decisions based on automated data processing without
          your prior consent. Most commonly, we will use your personal data in
          the following circumstances:
        </p>
        <p>
          • where we need to perform the contract we are about to enter into or
          have entered into with you;
          <br />
          • to provide you with our email notifications, newsletters and
          marketing communications at either your request or with your consent
          when completing a registration form for the service;
          <br />
          • where it is necessary for our legitimate interests and your
          interests and fundamental rights do not override those interests;
          <br />
          • to ensure that our service is delivered efficiently, including where
          we wish to notify you of any changes to the service, generate relevant
          content, tailor the website to your interests, and improve your
          visibility to potential collaborators;
          <br />
          • to provide customer service to you in relation to your use of the
          service, to deal with inquiries and complaints relating to the use of
          the service and to notify you about any changes to our service;
          <br />
          • to administer, support, improve, optimise and develop our service;
          <br />
          • to produce reports which may be used, sold, or published by us at
          our sole discretion provided that any data or reports will be
          anonymised and not refer to any individual user of the services;
          <br />
          • for internal record keeping and marketing and demographic studies to
          improve the services that we provide; <br />
          • for security purposes; <br />
          • where we need to comply with a legal or regulatory obligation; and
          <br />• to investigate a potential breach of our terms and conditions.
        </p>
        <p>
          <br />
          We will ask for your consent before using information for a purpose
          other than those that are set out in this privacy notice.
          <br />
          <br />
          <b>AUTOMATED DECISION MAKING</b>
        </p>
        <p>
          We may use automated decision-making in processing your personal
          information for some services and products. You can request a manual
          review of the accuracy of an automated decision if you are unhappy
          with it.
          <br />
          <br />
          <b>MARKETING</b>
        </p>
        <p>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising. We may use your
          contact details and technical data that we collect about you, such as
          your IP address and the way you browse our website to form a view of
          what we think you may want or need, or what may be of interest to you.
          This is how we decide which products, services and offers may be
          relevant for you (we call this marketing).
          <br />
          <br />
          You will receive marketing communications from us if you have
          requested information from us or purchased goods or services through
          us or if you provided us with your details when you entered a
          competition or registered for a promotion and, in each case, you have
          not opted out of receiving that marketing.
        </p>
        <p>
          <br />
          You can ask us to stop sending you marketing messages at any time by
          following the opt-out links on any marketing message sent to you or by
          contacting us at any time at privacy@zamna.com.
          <br />
          <br />
          <b>THIRD-PARTY MARKETING</b>
        </p>
        <p>
          We will get your express opt-in consent before we share your personal
          data with any external company for marketing purposes.
          <br />
          <br />
          <b>CHANGE OF PURPOSE</b>
        </p>
        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to know how the processing for the new purpose is
          compatible with the original purpose, please email us at
          privacy@zamna.com.
        </p>
        <p>
          <br />
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
          <br />
          <br />
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
          <br />
          <br />
          <b>HOW DO WE SHARE YOUR INFORMATION?</b>
        </p>
        <p>
          You acknowledge and agree that from time to time we have the right to
          share your personal information with:
          <br />
          • Any member of our group, which means our subsidiaries, our ultimate
          holding company and its subsidiaries, as defined in section 1159 of
          the UK Companies Act 2006.
          <br />
          • Selected third parties including but not limited to:
          <br />
          • Rocket Science Group, LLC for use of their service, Mailchimp
          (“Mailchimp”) based in the US, to send you our newsletter, if you
          consent; <br />
          • PayPal (Europe) S.à r.l. et Cie, S.C.A. (“PayPal”) based in the EU,
          for processing payments; <br />• Stripe Inc. (“Stripe”) based in the
          US, for processing payments; <br />
          • Amazon Web Services, Inc. (“AWS”), for our IT hosting on their
          servers based in Ireland; <br />
          • Google LLC. (“Google”) based in the US, for the storage of documents
          that include your personal data on Google Drive, the use of Gmail in
          order to contact you about any services we provide to you and Google
          Contacts to store and maintain our clients’ details;
          <br />
          • Xero Limited (“Xero”) based in the EU, Australia, Singapore and the
          US, to issue our clients with invoices for services we provide them
          with; <br />
          • Dropbox Inc. (“Dropbox”) based in the US, to store and share
          documents and digital files; <br />
          • Zoom Video Communications, Inc. (“Zoom”) based in the US, to
          communicate via video and share documents, some of which may contain
          information about you;
          <br />• Formagrid, Inc. (“Airtable”) based in the US, to manage our
          projects and workloads, which can sometimes include information about
          you; <br />• Notion Labs, Inc. (“Notion”) based in the US, to manage
          our projects and workloads, which can sometimes include information
          about you; <br />
          • Telegram, Inc (“Telegram”) based in the UAE, for instant internal
          communication which can sometimes be about you;
          <br />
          • select business partners, suppliers and subcontractors for the
          performance of any contract we enter into with you.
          <br />
          <br />
          Other than as expressly set out in this Privacy Notice or as otherwise
          required or permitted by law, we will not share, sell, or distribute
          any of the information you provide us without your consent.
        </p>
        <p>
          <br />
          We will disclose your personal information (i) to any third party to
          whom disclosure is necessary to provide you with services; (ii) where
          required to do so by law or court order; and (iii) to any person to
          whom disclosure is necessary to enable us to enforce our rights under
          this privacy notice or under our Terms and Conditions.
        </p>
        <p>
          <br />
          This includes when:
          <br />
          • We sell or buy any business or assets, in which case we will
          disclose your personal data to the prospective seller or buyer of such
          business or assets.
          <br />
          • The company or substantially all of its assets are acquired by a
          third party, in which case personal data held by it about its
          customers will be one of the transferred assets.
          <br />
          • If we are under a duty to disclose or share your personal data in
          order to comply with any legal obligation, or in order to enforce or
          apply our Website Terms and Conditions and other agreements; or to
          protect the rights, property, or safety of the company, our customers,
          or others. This includes exchanging information with other companies
          and organisations for the purposes of fraud protection and credit risk
          reduction.
          <br />
          <br />
          <b>WHERE DO WE STORE YOUR INFORMATION?</b>
        </p>
        <p>
          All personal data we process is processed by our staff in the United
          Kingdom.
        </p>
        <p>
          <br />
          For the purposes of IT hosting and maintenance, this information is
          located on servers inside the EEA. <br />
          <br />
          Once we have received your information, we have a Data Protection
          regime in place to oversee the effective and secure processing of your
          personal data and we will use strict procedures and security features
          to try to prevent unauthorised access.
        </p>
        <p>
          <br />
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted to our website at transmission stage; any transmission is
          at your own risk.
          <br />
          <br />
          <b>HOW LONG DO WE KEEP YOUR DATA FOR?</b>
        </p>
        <p>
          We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements. <br />
          <br />
          To determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>
        <p>
          <br />
          We are required under UK tax law to keep basic personal data about our
          customers (name, address, contact details) for a minimum of six (6)
          years after which time it will be destroyed.
        </p>
        <p>
          <br />
          In some circumstances, we may anonymise your personal data (so that it
          can no longer be associated with you) for research or statistical
          purposes in which case we may use this information indefinitely
          without further notice to you.
        </p>
        <p>
          <br />
          You can ask us to delete your data in some circumstances. See below
          for further information.
          <br />
          <br />
          <b>WHAT ARE YOUR RIGHTS?</b>
        </p>
        <p>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data:
          <br />
          • Request access to your personal data.
          <br />
          • Request correction of your personal data.
          <br />
          • Request erasure of your personal data.
          <br />
          • Object to processing of your personal data.
          <br />
          • Request restriction of processing your personal data.
          <br />
          • Request transfer of your personal data.
          <br />
          • Right to withdraw consent.
          <br />
          <br />
          <b>EXERCISING YOUR RIGHTS</b>
        </p>
        <p>
          If you wish to exercise any of your rights set out above, please email
          us at privacy@zamna.com.
        </p>
        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we may refuse to comply with your request in these
          circumstances.
          <br />
          <br />
          <b>THIRD-PARTY LINKS</b>
        </p>
        <p>
          Our website may contain links to websites or access to plug-ins and
          applications. We do not control and accept any liability or
          responsibility for third-party websites. This privacy notice is not
          applicable to other websites. Please consult the terms and conditions
          and privacy policies of third-party websites to find out how they
          collect and use your personal data.
          <br />
          <br />
          <b>SOCIAL MEDIA PLATFORM</b>
        </p>
        <p>
          All communications and actions carried out on third-party social media
          platforms will be subject to the terms and conditions and privacy
          policies of those websites. Please note that we will never ask for
          your personal or sensitive information through social media platforms.
          Please only discuss sensitive information through email.
        </p>
        <p>
          This website may use social sharing buttons which allow you to share
          web content quickly on a social media platform. Please use such
          buttons at your discretion as the third-party platform may track and
          save your request.
          <br />
          <br />
          <b>CHANGES TO OUR PRIVACY NOTICE</b>
        </p>
        <p>
          Any changes we make to our privacy notice in the future will be posted
          on this page and notified to you by e-mail. By continuing to use this
          website, you are agreeing to the changes that have been made, and any
          future changes, to the policy.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>{" "}
      </div>
    </div>
  );
}
