import Collapsible from "react-collapsible";
import clsx from "clsx";

import "../../../App.css";
import "./Faq.css";

import Content from "../../../content/content";
import Images from "../../../assets/images";
import { useAppStore } from "../../../stores/app-store";
import { ReactNode, useState } from "react";

import EnContent from "../../../content/locales/en/main.json";
const faqItems = EnContent.faq.items;

interface Props {
  className?: string;
}

export function Faq(p: Props) {
  const t = Content.shared.t;
  const language = useAppStore((state) => state.language);

  const [openedFaq, setOpenedFaq] = useState<Set<string>>(new Set());

  return (
    <div id="faq-section" className={clsx("faq", p.className)}>
      <div className="page-section">
        <h2 className="faq-title">{`${t("faq.title")}`}</h2>

        {faqItems.map((_, index) => {
          const id = `faq-${index}`;
          const question = t(`faq.items.${index}.question`);
          const isOpen = openedFaq.has(id);

          return (
            <div className={clsx("faq-container", isOpen && "open")} key={`faq-container-${index}`}>
              <Collapsible
                trigger={<Trigger title={question} isOpen={isOpen} />}
                onOpening={() => handleOpen(id)}
                onClosing={() => handleClose(id)}
                transitionTime={200}
                transitionCloseTime={200}
                className="faq-item"
              >
                {getAnswer(index)}
              </Collapsible>
            </div>
          );
        })}
      </div>
    </div>
  );

  function getAnswer(index: number): ReactNode {
    const answerList = faqItems[index].answer;

    return (
      <div className="faq-answer">
        {answerList.map((answer, i) => {
          const partHasBulletPoints =
            answer["bullet-points"] && answer["bullet-points"].length > 0;

          return (
            <div className="answer-part" key={`answer-${i}`}>
              {`${t(`faq.items.${index}.answer.${i}.paragraph`)}`}

              {partHasBulletPoints && (
                <ul className="answer-bullet-points">
                  {answer["bullet-points"].map((_, j) => {
                    return (
                      <li key={`bullet-point-${j}`}>
                        {`${t(
                          `faq.items.${index}.answer.${i}.bullet-points.${j}`
                        )}`}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  function handleClose(id: string) {
    const set = new Set(openedFaq);
    if (openedFaq.has(id)) {
      set.delete(id);
    }

    setOpenedFaq(set);
  }

  function handleOpen(id: string) {
    const set = new Set(openedFaq);

    if (!openedFaq.has(id)) {
      set.add(id);
    }

    setOpenedFaq(set);
  }
}

interface TriggerProps {
  title: String;
  isOpen?: boolean;
}

function Trigger(p: TriggerProps) {
  return (
    <div className="faq-trigger">
      <p className="faq-question">{p.title}</p>
      <img src={p.isOpen ? Images.faq.minus : Images.faq.plus} />
    </div>
  );
}
