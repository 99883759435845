import i18next, { i18n, TFunction } from "i18next";

export enum ContentNS {
  main = "main",
  business = "business",
}

const namespaces = Object.values(ContentNS);

interface StringMap {
  [key: string]: string;
}

class Content {
  static shared: Content = new Content();
  ready: boolean = false;
  instance: i18n = i18next;
  t: TFunction = i18next.t;

  private constructor() {}

  async setup(
    language: string,
    supportedLanguages: string[],
    namespaces: ContentNS[],
    callback: () => void
  ) {
    var resources: { [key: string]: StringMap } = {};

    supportedLanguages.forEach((locale) => {
      var nsDict: StringMap = {};

      namespaces.forEach((ns) => {
        const translations = require(`./locales/${locale}/${ns}.json`);
        nsDict[ns] = translations;
      });

      resources[locale] = nsDict;
    });

    await i18next.init(
      {
        lng: language,
        resources,
        // todo: take from config and use in appstore from shared_instance language
        fallbackLng: "en",
        ns: Object.values(ContentNS),
        defaultNS: namespaces[0],
        debug: false,
      },
      callback
    );
  }

  changeLanguage(locale: string) {
    this.instance.changeLanguage(locale);
  }
}

export default Content;
