import "../../../App.css";
import "./ProtectingData.css";

import Content from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";

interface Props {
  className?: string;
}

export function ProtectingData(p: Props) {
  const t = Content.shared.t;

  return (
    <div className={clsx("protecting-data", p.className)}>
      <div className="protecting-data-header">
        <div className="page-section">
          <h2 className="protecting-data-title">
            {`${t("protecting-data-title")}`}
          </h2>
          <img src={Images.flowDesktop} className="data-flow-img-desktop" />
          <img src={Images.flowMobile} className="data-flow-img-mobile" />
        </div>
      </div>
      <div className="protecting-data-body">
        <div className="page-section">
          <div className="protecting-data-text">
            {`${t("protecting-data-text")}`}
          </div>
        </div>
      </div>
    </div>
  );
}
