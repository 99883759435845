import "../../../App.css";
import "./WhoWeAre.css";

import Content from "../../../content/content";
import clsx from "clsx";

interface Props {
  className?: string;
}

export function WhoWeAre(p: Props) {
  const t = Content.shared.t;

  return (
    <div className={clsx("who-we-are", p.className)}>
      <div className={"page-section"}>
        <div className="who-we-are-content">
          <h2 className="who-we-are-question">{`${t(
            "who-we-are-question"
          )}`}</h2>
          <p>{`${t("who-we-are-answer")}`}</p>
        </div>
      </div>
    </div>
  );
}
