import "../../../App.css";
import "./HowBanners.css";

import Content from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";
import { useAppStore } from "../../../stores/app-store";

interface Props {
  className?: string;
}

export function HowBanners(p: Props) {
  const t = Content.shared.t;
  const language = useAppStore((state) => state.language);

  return (
    <div className={clsx("how-banners", p.className)}>
      <div className="page-section">
        <div className="how-banner">
          <div className="how-banner-content">
            <h2 className="how-title">{`${t("stay-in-control-title")}`}</h2>
            <img className="how-image-mobile" src={Images.consent(language)} />
            <p className="how-text">{`${t("stay-in-control-text-1")}`}</p>
            <p className="how-text">{`${t("stay-in-control-text-2")}`}</p>
          </div>
          <img className="how-image-desktop" src={Images.consent(language)} />
        </div>

        <div className="how-banner">
          <img
            className="how-image-desktop"
            src={Images.safeStorage(language)}
          />
          <div className="how-banner-content">
            <h2 className="how-title">{`${t("how-zamna-title")}`}</h2>
            <img
              className="how-image-mobile"
              src={Images.safeStorage(language)}
            />
            <p className="how-text">{`${t("how-zamna-text")}`}</p>

            <div className="how-banner-faq-advice">
              <span>{`${t("how-zamna-faq-1")}`}</span>
              <span>{`${t("how-zamna-faq-2")}`}</span>
              <span>{`${t("how-zamna-faq-3")}`}</span>
            </div>

            <a className="faq-button" href="#faq-section">
              {`${t("ui.button.faq")}`}
            </a>
          </div>
        </div>

        <div className="how-banner">
          <div className="how-banner-content">
            <h2 className="how-title">{`${t("how-help-title")}`}</h2>
            <img className="how-image-mobile" src={Images.readyAtHome} />
            <p className="how-text">{`${t("how-help-text-1")}`}</p>
            <p className="how-text">{`${t("how-help-text-2")}`}</p>
          </div>
          <img className="how-image-desktop" src={Images.readyAtHome} />
        </div>
      </div>
    </div>
  );
}
