import "../../../App.css";
import "./WeWork.css";

import clsx from "clsx";
import Slider from "react-slick";

import Images from "../../../assets/images";
import Config from "../../../config";
import Content, { ContentNS } from "../../../content/content";

interface Props {
  className?: string;
}

export function WeWork(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();

  const partners = [
    { img: Images.logos.b2bIag },
    { img: Images.logos.b2bAaco },
    { img: Images.logos.b2bIcao },
    { img: Images.logos.b2bIco },
    { img: Images.logos.b2bWestJet },
    { img: Images.logos.b2bVolaris },
    { img: Images.logos.b2bBa },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: Config.carouselDelay,
    centerMode: true,
    variableWidth: true,
  };

  return (
    <div className={clsx("we-work", p.className)}>
      <h6 className="we-work-title" dir={textDir}>{`${t("we-work-with", {
        ns: ContentNS.business,
      }).toUpperCase()}`}</h6>

      <div className="we-work-partners">
        <Slider {...settings}>
          {partners.map(({ img }) => (
            <div className="carousel-item" key={img}>
              <div>
                <img
                  src={img}
                  alt="logo of zamna partner"
                  className="partner-logo"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
