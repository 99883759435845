import clsx from "clsx";
import { Fragment, TouchEvent, useEffect, useRef, useState } from "react";
import Images from "../../assets/images";
import { isMobileDevice } from "../../utils";
import "./QuotesSlider.css";

let waitThrottle = false;

const isMobile = isMobileDevice();

const SLIDE_REPLICAS_COUNT = 8;
const SLIDE_WIDTH = isMobile ? 343 : 456;
const SLIDE_MARGIN = 12;

const BASE_SLIDER_WIDTH = isMobile ? 375 : 1440;
const BASE_SLIDER_HEIGHT = isMobile ? 420 : 360;

const TOUCH_SWIPE_THRESHOLD = 60;
const TOUCH_VERTICAL_SWIPE_THRESHOLD = 40;
const TOUCH_MOVE_THRESHOLD = 0;

interface Quote {
  id?: string;
  text: string;
  logo: string;
  jobTitle: string;
}

const quotes: Quote[] = [
  {
    text: "That is what is missing - a [super secure] centralised passport repository, worldwide.",
    logo: Images.quotesLogos.caribbean,
    jobTitle: "CIO",
  },
  {
    text: "With Zamna technology powering verification requirements for check-in on select flights between Canada and London UK, our guests will enjoy complete assurance that their travel documents are fully compliant in order to fly.",
    logo: Images.quotesLogos.westjet,
    jobTitle: "VP Guest Experience",
  },
  {
    text: "Totally get the value prop to the airlines, an authoritative source that can automate and digitally take care of what’s now a manual and human process. No brainer, totally get it.",
    logo: Images.quotesLogos.travelPort,
    jobTitle: "Vice President",
  },
  {
    text: "Knowing the complexities of airline logistics, I can say Zamna's technology is a game-changer. It streamlines doc checks before passengers even arrive at the airport. Allowing airlines to steer clear of unnecessary fees, significantly saving time and resources.",
    logo: Images.quotesLogos.canaero,
    jobTitle: "Former Director",
  },
  {
    text: "I’m very interested because I’ve been talking to the big guys and everything [rules, documentation, regs] is becoming more complicated.",
    logo: Images.quotesLogos.airArabia,
    jobTitle: "Head of IT",
  },
  {
    text: "There’s nothing expected from the passenger to do. So that’s a competitive advantage for your company.",
    logo: Images.quotesLogos.ethiopian,
    jobTitle: "Senior Customer Service Officer",
  },

  //   {
  //     text: "6",
  //     logo: Images.quotesLogos.ba,
  //     jobTitle: "string",
  //   },
];

let startItems: Quote[] = [];

for (let i = 0; i < SLIDE_REPLICAS_COUNT; i++) {
  const quotesWithIds: Quote[] = quotes.map((q, j) => {
    return { ...q, id: `quote-${i}-${j}` };
  });
  startItems = [...startItems, ...quotesWithIds];
}

const startingSlideIdx = Math.ceil((SLIDE_REPLICAS_COUNT * quotes.length) / 2);

interface LocalState {
  items: Quote[];
  slideIdx: number;
  stripPositionLeft: number;
  touchStartX: number;
  translateX: number;
}

export default function QuotesSlider() {
  const touchStartXRef = useRef(0);
  const touchStartYRef = useRef(0);
  const touchCurrentXRef = useRef(0);
  const touchCurrentYRef = useRef(0);
  const leftPositionOnTouchStart = useRef(0);
  const sliderStripRef = useRef(null);
  const leftPositionRef = useRef(0);

  if (isMobile) {
    leftPositionRef.current = -SLIDE_WIDTH - SLIDE_MARGIN * 1.6
  } else if (window.innerWidth < BASE_SLIDER_WIDTH){
      leftPositionRef.current = Math.round((window.innerWidth - BASE_SLIDER_WIDTH) / 2);
  }

  const initialTranslateX = -startingSlideIdx * (SLIDE_WIDTH + 2 * SLIDE_MARGIN) + leftPositionRef.current

  const translateXRef = useRef(initialTranslateX);

  // translateXRef.current =

  const [state, _setState] = useState<LocalState>({
    items: startItems,
    slideIdx: startingSlideIdx,
    stripPositionLeft: 0,
    touchStartX: 0,
    translateX: initialTranslateX
  });

  const { items, slideIdx, translateX } = state;
  const stateRef = useRef(state);

  const setState = (data: LocalState) => {
    stateRef.current = data;
    _setState(data);
  };

  useEffect(adjustPosition, [sliderStripRef]);

  return (
    <div
      id="q-slider"
      className="quotes-slider"
      style={{ height: BASE_SLIDER_HEIGHT }}
    >
      <div className="quotes-slider-content">
        <div
          className="quotes-slide-strip"
          ref={sliderStripRef}
          style={{
            transform: `translate(${translateX}px, 0)`,
          }}
        >
          {items.map((q, i) => {
            return (
              <div
                id={q.id}
                key={i}
                style={{ width: SLIDE_WIDTH }}
                className={clsx(
                  "quote-item",
                  i - slideIdx === 1 && "highlighted"
                )}
              >
                <div className="quote-text">{q.text}</div>
                <div className="quote-author">
                  <div className="quote-author-title">{q.jobTitle}</div>
                  <img
                    alt="customer logo"
                    src={q.logo}
                    className="company-logo"
                  />
                </div>
              </div>
            );
          })}
        </div>

        <button className="next-slide-button" onClick={nextSlide} />
        <button className="previous-slide-button" onClick={previousSlide} />
      </div>
      <div className="slider-knobs-container">
        <div className="slider-knobs">
          {quotes.map((q, idx) => {
            return (
              <div
              key = {idx}
                className="slider-knob-touch-zone"
                onClick={() => navigateToSlide(idx)}
              >
                <div
                  className={clsx(
                    "slider-knob",
                    (slideIdx + 1) % quotes.length === idx && "highlighted"
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  // todo: handle exceptions
  function handleTouchStart(e: any) {
    touchCurrentXRef.current = e.touches[0].clientX;
    touchCurrentYRef.current = e.touches[0].clientY;

    const TOUCH_START_THRESHOLD = 30;

    if (!sliderStripRef.current) {
      return;
    }

    (sliderStripRef.current as HTMLDivElement).classList.add(
      "without-transition"
    );

    if (
      Math.abs(touchStartXRef.current - touchCurrentXRef.current) <
      TOUCH_START_THRESHOLD
    ) {
      return;
    }

    touchStartXRef.current = e.touches[0].clientX;
    touchStartYRef.current = e.touches[0].clientY;
    leftPositionOnTouchStart.current = stateRef.current.stripPositionLeft;
  }

  function handleTouchEnd(e: any) {
    if (!sliderStripRef.current) {
      return;
    }

    const diff = touchStartXRef.current - touchCurrentXRef.current;

    const direction =
      Math.abs(diff) < TOUCH_SWIPE_THRESHOLD ? 0 : Math.sign(diff);

    (sliderStripRef.current as HTMLDivElement).classList.remove(
      "without-transition"
    );

    translateXRef.current =
      -(stateRef.current.slideIdx + direction) *
        (SLIDE_WIDTH + 2 * SLIDE_MARGIN) +
      leftPositionRef.current;

    setState({
      ...stateRef.current,
      slideIdx: stateRef.current.slideIdx + direction,
      translateX: translateXRef.current,
    });
  }

  function handleTouchMove(e: any) {
    // e.preventDefault()
    const x = e.touches[0].clientX;
    const y = e.touches[0].clientY;

    const diffX = touchStartXRef.current - touchCurrentXRef.current;
    const diffY = touchStartYRef.current - touchCurrentYRef.current;

    // console.log("moving, translateX, diff:", translateXRef.current, stateRef.current, diffX, diffY);

    // if (Math.abs(diffY) < TOUCH_VERTICAL_SWIPE_THRESHOLD) {
    //   e.preventDefault()
    // }

    // console.log("diff, slideIdx:", diffX, stateRef.current.slideIdx);

    if (
      (diffX < 0 && stateRef.current.slideIdx <= 0) ||
      (diffX > 0 &&
        stateRef.current.slideIdx >=
          Math.ceil(SLIDE_REPLICAS_COUNT * quotes.length - 3))
    ) {
      return;
    }

    touchCurrentXRef.current = x;
    touchCurrentYRef.current = y;

    if (Math.abs(diffX)< TOUCH_MOVE_THRESHOLD) {
      return
    }

    setState({
      ...stateRef.current,
      translateX: translateXRef.current - diffX,
    });
  }

  function adjustPosition() {
    const slider = document.getElementById("q-slider");
    if (!slider) {
      return;
    }

    slider.addEventListener("touchstart", handleTouchStart);
    slider.addEventListener("touchmove", handleTouchMove);
    slider.addEventListener("touchend", handleTouchEnd);

    return () => {
      slider.removeEventListener("touchstart", handleTouchStart);
      slider.removeEventListener("touchmove", handleTouchMove);
    };
  }

  function navigateToSlide(knobIdx: number) {
    const diff = knobIdx - ((slideIdx + 1) % quotes.length);

    //  todo, check not current slide idx but wiht diff already
    if (
      (diff < 0 && stateRef.current.slideIdx === 0) ||
      (diff > 0 &&
        stateRef.current.slideIdx >=
          Math.ceil(SLIDE_REPLICAS_COUNT * quotes.length - 3))
    ) {
      return;
    }

    translateXRef.current =
      -(slideIdx + diff) * (SLIDE_WIDTH + 2 * SLIDE_MARGIN) +
      leftPositionRef.current;

    setState({
      ...state,
      slideIdx: slideIdx + diff,
      translateX: translateXRef.current,
    });
  }

  function nextSlide() {
    if (slideIdx === Math.ceil(SLIDE_REPLICAS_COUNT * quotes.length - 3)) {
      return;
    }

    setState({
      ...state,
      slideIdx: slideIdx + 1,
      translateX:
        -(slideIdx + 1) * (SLIDE_WIDTH + 2 * SLIDE_MARGIN) +
        leftPositionRef.current,
    });
  }

  function previousSlide() {
    if (slideIdx === 0) {
      return;
    }
    setState({
      ...state,
      slideIdx: slideIdx - 1,
      translateX:
        -(slideIdx - 1) * (SLIDE_WIDTH + 2 * SLIDE_MARGIN) +
        leftPositionRef.current,
    });
  }
}
