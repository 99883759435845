import "../../../App.css";
import "./Awards.css";

import Content, { ContentNS } from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";

import Slider from "react-slick";
import Config from "../../../config";

interface Props {
  className?: string;
}

export function Awards(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir()

  const awards = [
    {
      img: Images.logos.awardsLufthansa,
      text: `${t("awards.lufthansa", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.awardsIata,
      text: `${t("awards.iata", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.awardsEmirates,
      text: `${t("awards.emirates", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.awardsPhocus,
      text: `${t("awards.phocus", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.awardsAirfrance,
      text: `${t("awards.airfrance", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.hangar51,
      text: `${t("awards.hangar51", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.businessAwards,
      text: `${t("awards.london-business", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.eit,
      text: `${t("awards.eit", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.travelHospitality,
      text: `${t("awards.travel-hospitality", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.aviation,
      text: `${t("awards.aviation-festival", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.ukbaa,
      text: `${t("awards.ukbaa-1", { ns: ContentNS.business })}`,
    },
    {
      img: Images.logos.ukbaa,
      text: `${t("awards.ukbaa-2", { ns: ContentNS.business })}`,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: Config.carouselDelay,
    centerMode: true,
    variableWidth: true,
    // adaptiveHeight: false,
    // mobileFirst: true,
  };

  return (
    <div className={clsx("awards", p.className)}>
      <div className={clsx("awards-container")}>
        <h6 className="awards-title" dir={textDir}>{`${t("awards.title", {
          ns: ContentNS.business,
        }).toUpperCase()}`}</h6>

        <div className="awards-content">
          <Slider {...settings}>
            {awards.map(({ img, text }) => (
              <div className="carousel-item" key={img}>
                <div className="awards-item">
                  <img
                    src={img}
                    alt="logo of zamna partner"
                    className="award-logo"
                  />
                  <div className="award-title">{text}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
