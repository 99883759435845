export enum ConsentCookieValue {
  yes = "yes",
  no = "no",
}

export default class ConsentCookie {
  static cookieName = "consent";
  static setConsentCookie(consent: boolean) {
    const expirationDate = new Date(2037, 12, 10);

    document.cookie = `${ConsentCookie.cookieName}=${
      consent ? ConsentCookieValue.yes : ConsentCookieValue.no
    }; expires=${expirationDate.toUTCString()}; path=/`;
    //    console.log("consent cookie:", document.cookie);
  }

  static getConsentCookie(): string | null {
    return getCookie(ConsentCookie.cookieName);
  }
}

function getCookie(name: string): string | null {
  return (
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
    null
  );
}
