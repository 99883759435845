import "../../../App.css";
import "./LinksSection.css";

import Content from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";

import Slider from "react-slick";

import { useRef } from "react";
import { PressCarousel } from "./PressCarousel";
import Config from "../../../config";

interface Props {
  className?: string;
}

export function LinksSection(p: Props) {
  const t = Content.shared.t;
  const mentionLogo = useRef(null);
  const mentionedIn = [
    {
      url: "https://www.travolution.com/news/travel-sectors/air/westjet-to-pilot-digital-travel-documentation-verification-system/",
      img: Images.logos.travolution,
    },
    {
      url: "https://2zfq4r3trpub14lqo62u0d9p-wpengine.netdna-ssl.com/wp-content/uploads/2021/02/Zamna-The-TimesRaconteur.pdf",
      img: Images.logos.times,
    },
    {
      url: "https://youtu.be/GXcTnN_wlg8",
      img: Images.logos.sky,
    },
    {
      url: "https://www.wired.co.uk/article/passport-check-blockchain",
      img: Images.logos.wired,
    },
    {
      url: "https://www.bbc.co.uk/news/business-45628825",
      img: Images.logos.bbc,
    },
    {
      url: "https://www.forbes.com/sites/oliversmith/2018/05/21/blockchain-startup-vchain-is-flying-high-after-its-royal-seal-of-approval/?sh=3f38f5721ded",
      img: Images.logos.forbes,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: Config.carouselDelay,
    centerMode: true,
    variableWidth: true,
  };

  return (
    <div className={clsx("links-section", p.className)}>
      <div className="links-section-content">
        <h6 className="links-title">{`${t("participating")}`}</h6>
        <div className="participating">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.iairgroup.com/"
          >
            <img src={Images.logos.iag} className="partner-logo" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.westjet.com/"
          >
            <img src={Images.logos.westjet} className="partner-logo" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.volaris.com/"
          >
            <img src={Images.logos.volaris} className="partner-logo" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.britishairways.com/"
          >
            <img src={Images.logos.ba} className="partner-logo" />
          </a>
        </div>

        <PressCarousel />
      </div>
    </div>
  );
}
