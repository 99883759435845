import "./DemoPageButton.css";

import { ComponentPropsWithoutRef } from "react";

import Images from "../../../assets/images";
import Config from "../../../config";
import Content, { ContentNS } from "../../../content/content";

interface LiveDemosButtonProps extends ComponentPropsWithoutRef<"a"> {
  lang: string;
}

export default function LiveDemosButton({
  lang,
  className,
  ...props
}: LiveDemosButtonProps) {
  const t = Content.shared.t;
  return (
    <a
      className={`demo-page-button ${className ? className : ""}`}
      {...props}
      href={Config.demoPageUrl(lang)}
    >
      <p>{`${t("demo-page-button")}`}</p>
      <img
        className="demo-page-button-arrow-icon"
        src={Images.icons.arrowRight}
        alt="arrow right"
      />
    </a>
  );
}
