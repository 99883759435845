import "../../../App.css";
import "./NewsBlock.css";

import Content, { ContentNS } from "../../../content/content";
import Images from "../../../assets/images";
import { extenalLinks } from "../../../content/external-links";
import clsx from "clsx";

interface Props {
  className?: string;
}

export function NewsBlock(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();

  return (
    <div className="news-block">
      <div className="news-block-content">
        <div className="news-block-title" dir={textDir}>
          {`${t("news.title", { ns: ContentNS.business })}`}
        </div>

        <div className="news-container">
          <div className={clsx("news-item", `dir-${textDir}`)}>
            <img className="news-thumbnail" src={Images.b2bNews4} />
            <div className="news-description">
              <div className="news-item-title" dir={textDir}>{`${t(
                "news.items.0.title",
                {
                  ns: ContentNS.business,
                }
              )}`}</div>
              <div className="news-item-subtitle">
                {`${t("news.items.0.subtitle", { ns: ContentNS.business })}`}
              </div>

              <div className={clsx("read-more", `dir-${textDir}`)}>
                <a
                  className="read-more-link"
                  href={extenalLinks.b2bnews4}
                  target="_blank"
                  rel="noopener noreferrer"
                  dir={textDir}
                >
                  {`${t("news.read-more", { ns: ContentNS.business })}`}
                </a>
                <img
                  alt="right chevron"
                  className="read-more-chevron"
                  src={Images.icons.chevronRightBlue}
                />
              </div>
            </div>
          </div>
          {/*<div className={clsx("news-item", `dir-${textDir}`)}>
            <div className="news-description">
              <div className={clsx("news-item-title", `dir-${textDir}`)}>
                {`${t("news.items.1.title", { ns: ContentNS.business })}`}
              </div>
              <div className="news-item-subtitle">{`${t(
                "news.items.1.subtitle",
                {
                  ns: ContentNS.business,
                }
              )}`}</div>
              <div className={clsx("read-more", `dir-${textDir}`)}>
                <a
                  className="read-more-link"
                  href={extenalLinks.b2bnews3}
                  target="_blank"
                  rel="noopener noreferrer"
                >{`${t("news.read-more", {
                  ns: ContentNS.business,
                })}`}</a>
                <img
                  className="read-more-chevron"
                  src={Images.icons.chevronRightBlue}
                />
              </div>
            </div>
            <img className="news-thumbnail" src={Images.b2bNews3} />
          </div>*/}
        </div>
      </div>
    </div>
  );
}
