import "../../../App.css";
import "./AsSeenIn.css";

import Content, { ContentNS } from "../../../content/content";
import clsx from "clsx";
import Images from "../../../assets/images";

import Slider from "react-slick";

import { useRef } from "react";
import { LinksSection } from "../../common/links-section/LinksSection";
import { PressCarousel } from "../../common/links-section/PressCarousel";

interface Props {
  className?: string;
}

export function AsSeenIn(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir()

  return (
    <div className={clsx("as-seen-in", p.className)}>
        <h6 className="as-seen-in-title" dir={textDir}>
          {`${t("as-seen", {ns: ContentNS.business})}`}
          </h6>
        <PressCarousel/>
    </div>
  );
}
