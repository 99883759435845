export default class Config {
  static carouselDelay = 1000;
  // prettier-ignore
  static supportedLanguages = {
        b2b: [
          "en",
          "es",
          "fr",
          "pt",
          "ar"
        ],
        b2c: [
          "en",
          "es",
          "fr",
          "pt"
        ],
    }
  static demoPageUrl = (lang: string) => {
    switch (lang) {
      case "ar":
        return `https://demo.zamna.com/Arabic`;
      case "es":
        return `https://demo.zamna.com/Spanish`;
      default:
        return `https://demo.zamna.com/English`;
    }
  };
}
