import "../../../App.css";
import "./BusinessFooter.css";

import clsx from "clsx";

import Images from "../../../assets/images";
import Content, { ContentNS } from "../../../content/content";
import { extenalLinks } from "../../../content/external-links";

export default function BusinessFooter() {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();

  return (
    <div id="footer" className="business-footer">
      <div className="business-footer-content">
        <div className="footer-row-2">
          <div className="footer-copyright">
            {`${t("footer.copyright", { ns: ContentNS.main })}`}
          </div>
          <a
            target="_blank"
            href={extenalLinks.privacyNotice}
            rel="noopener noreferrer"
            dir={textDir}
          >{`${t("footer.privacy-notice")}`}</a>
        </div>
      </div>
    </div>
  );
}
