import "./BusinessWebsitePicker.css";

import { useEffect, useRef, useState } from "react";

import Images from "../../../assets/images";
import Content from "../../../content/content";
import { Page, useAppStore } from "../../../stores/app-store";

interface Props {
  handleOpenStateChange: (open: boolean) => void;
  open: boolean;
}

export default function BusinessWebsitePicker(p: Props) {
  const [open, setOpen] = useState(false);
  const t = Content.shared.t;

  const listRef = useRef<HTMLDivElement>(null);
  const openRef = useRef<boolean>(false);

  const currentPage = useAppStore((state) => state.currentPage);

  useEffect(outsideClickEffect, []);
  useEffect(() => {
    setOpen(p.open);
  }, [p.open]);

  return (
    <div className="business-website-picker">
      <div
        className="business-picker-button"
        onClick={onItemClick}
        onTouchStart={onItemClick}
      >
        <div className="left-content">
          <img
            className="target-icon plane"
            alt="icon of plane"
            src={getIconForSelected(currentPage)}
          />
          <div className="target-name">{getSelectedPageTitle(currentPage)}</div>
        </div>
        <img
          className="chevron-icon dimmed"
          alt="icon of a chevron pointing down"
          src={Images.icons.chevronDown}
        />
      </div>

      {open && (
        <div className="language-list" ref={listRef}>
          <div
            className="language-list-item"
            onClick={() => redirectTo(currentPage)}
            onTouchEnd={() => redirectTo(currentPage)}
          >
            <img
              className="target-icon"
              alt="icon of a plane"
              src={getIconForOther(currentPage)}
            />
            <div className="language-list-item-title">
              {getOtherPageTitle(currentPage)}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function onItemClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    p.handleOpenStateChange(true);
    setOpen(true);
  }

  function outsideClickEffect() {
    function handleClick(e: Event) {
      if (listRef.current === null) {
        return;
      }

      if (e.target === null) {
        return;
      }

      if (!listRef.current.contains(e.target as HTMLElement)) {
        setOpen(false);
        openRef.current = false;
      }
    }

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }

  function getSelectedPageTitle(page: Page): string {
    switch (page) {
      case Page.passenger:
        return t("ui.button.for-passengers");
      default:
        return t("ui.button.for-companies");
    }
  }

  function getIconForSelected(page: Page): string {
    switch (page) {
      case Page.passenger:
        return Images.icons.pax;
      default:
        return Images.icons.planeBlue;
    }
  }

  function getIconForOther(page: Page): string {
    switch (page) {
      case Page.passenger:
        return Images.icons.planeBlue;
      default:
        return Images.icons.pax;
    }
  }

  function getOtherPageTitle(page: Page): string {
    switch (page) {
      case Page.passenger:
        return t("ui.button.for-companies");
      default:
        return t("ui.button.for-passengers");
    }
  }

  function redirectTo(page: Page) {
    setOpen(false);
    const location = window.location;
    switch (page) {
      case Page.passenger:
        window.location.assign(
          `${location.origin}/${Page.business}/${location.search}`
        );
        break;
      default:
        window.location.assign(
          `${location.origin}/${Page.passenger}/${location.search}`
        );
    }
  }
}
