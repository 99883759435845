import { useAppStore } from "../../stores/app-store";
import BusinessFooter from "./business-footer/BusinessFooter";
import LiveDemosButton from "./demo-page-button/DemoPageButton";
import GotQuestions from "./got-questions/GotQuestions";
import { ScanningIsNotEnoughVideo } from "./scanning-is-not-enough-video/ScanningIsNotEnoughVideo";

export function ScanningIsNotEnough() {
  const lang = useAppStore((state) => state.language);

  return (
    <div className="business-page">
      <ScanningIsNotEnoughVideo />
      <LiveDemosButton lang={lang} />

      <GotQuestions />
      <BusinessFooter />
    </div>
  );
}
