import "../../../App.css";
import "./WhatClientsSay.css";

import clsx from "clsx";

import Content, { ContentNS } from "../../../content/content";
import QuotesSlider from "../../quotes-slider/QuotesSlider";

interface Props {
  className?: string;
}

export function WhatClientsSay(p: Props) {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();

  return (
    <div id="clients" className={clsx("what-clients-say", p.className)}>
      <div className="what-clients-say-title" dir={textDir}>
        {`${t("clients-say", { ns: ContentNS.business })}`}
      </div>
      <QuotesSlider />
    </div>
  );
}
